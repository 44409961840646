<template>
  <div v-if="$apollo.loading">Loading...</div>
  <div v-else>
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="container">
      <div class="main main-raised" style="background:#fff;">
        <div class="section">
          <!-- end page title -->
          <div class="container">
            <b-form class="form" @submit.stop.prevent="submit">
              <div class="row">
                <div class="col-lg-8"></div>
                <div class="col-lg-4">
                  <div class="float-end ">
                    <v-btn
                      class="ma-2"
                      color="orange darken-2"
                      dark
                      href="/pim/assortments"
                    >
                      <v-icon dark left> mdi-arrow-left </v-icon>Back
                    </v-btn>

                    <v-btn
                      type="submit"
                      class="ma-2"
                      color="green darken-2"
                      dark
                    >
                      Save
                      <v-icon dark right>
                        mdi-checkbox-marked-circle
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                    <v-card  color="#386498">
                      <div class="container">
                      <v-card-text>
                        <div class="display-4 text-white font-weight-bold">
                          <strong> {{ name }} </strong>
                        </div>
                        <v-row align="center" justify="center">
                          <v-col class="text-right" cols="12">
                            <a
                              class="ml-3 btn text-white btn-danger"
                              @click="deleteAssortment()"
                            >
                              Delete Assortment
                            </a>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      </div>
                    </v-card>

                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-6">

                                    <div class=" row">
                            <label class="col-5 ">
                              <Strong>Name </Strong></label
                            >
                            <div class="col-7">
                              <input
                                id="name"
                                v-model="name"
                                class="form-control"
                                type="text"
                                required
                              />
                            </div>
                          </div>
                          <div class=" row">
                            <label class="col-5 ">
                              <Strong>Channels Used In </Strong></label
                            >
                            <div class="col-7">
                              {{ channels.join() }}
                            </div>
                          </div>

                          <div class=" row">
                            <label class="col-5 ">
                              <Strong>Date Created </Strong></label
                            >
                            <div class="col-7">
                              {{ created_at | date_format }}
                            </div>
                          </div>
                
                    
                        </div>

                        <div class="col-lg-6">
                             <div >

                  
                       <div class=" row">
                            <label class="col-5 ">
                              <Strong>Status </Strong></label
                            >
                            <div class="col-7">
                              <b-form-select
                                id="input-horizontal"
                                v-model="status"
                                :options="assortment_options"
                              ></b-form-select>
                            </div>
                          </div>

                    <!-- <img
                      id="image_cover"
                      style="width: 100%; max-height: 480px;"
                      :src="
                        main_image_url != ''
                          ? baseurl + main_image_url
                          : '/media/goose-placeholder.jpg'
                      "
                    /> -->
                    <!-- <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
          ></v-img> -->

                    <!-- <v-card-actions>
                      <b-form-file
                        v-model="main_image"
                        :state="Boolean(main_image)"
                        :placeholder="
                          main_image_name == ''
                            ? 'Upload Image'
                            : main_image_name
                        "
                        drop-placeholder="Drop file here..."
                        accept="image/*"
                        @change="imageChange"
                      ></b-form-file>

                      <v-spacer></v-spacer>
                      <a class="btn btn-warning ml-4 text-wh" @click="imageDelete"
                        >Delete</a
                      >
                    </v-card-actions> -->

                    <v-card-text>
                      <!-- <div class="row">
                        <div class="col-lg-12">
                          <div class="h5 text-dark">Tags:</div>

                          <div class="form-group row mt-4">
                            <v-combobox
                              v-model="chips"
                              :items="tagNames"
                              chips
                              clearable
                              :search-input.sync="chipsearch"
                              label="Enter Tags followed by Enter key."
                              multiple
                              hide-selected
                              solo
                              hint="Tags are great for using rules to sort or add"
                              @change="chipsearch = ''"
                            >
                              <template
                                #selection="{
                                  attrs,
                                  item,
                                  select,
                                  selected,
                                }"
                              >
                                <v-chip
                                  v-bind="attrs"
                                  :input-value="selected"
                                  close
                                  @click="select"
                                  @click:close="removeChip(item)"
                                >
                                  <strong>{{ item }}</strong
                                  >&nbsp;
                                </v-chip>
                              </template>
                              <template #no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      No results matching "<strong>{{
                                        search
                                      }}</strong
                                      >". Press <kbd>enter</kbd> to create a new
                                      one
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-combobox>
                          </div>
                        </div>
                      </div> -->
                    </v-card-text>
                    <v-spacer> </v-spacer>
                  
                </div>
                          <!-- <div class="form-group row">
                            <label class="col-12 col-form-label ">
                              <strong>Description </strong></label
                            >

                            <div class="col-12">
                              <ckeditor
                                v-model="description"
                                :editor="editor"
                                :config="editorConfig"
                              >
                              </ckeditor>

                              <pre class="mt-3 mb-0"></pre>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                </div>

             
              </div>
              <div
                class="v-card--material pa-3 px-5 mt-3 mb-5 v-card v-sheet theme--light v-card--material--has-heading"
              >
                <v-toolbar color="cyan" flat>
                  <v-toolbar-title> Products</v-toolbar-title>

                  <v-spacer></v-spacer> <v-spacer></v-spacer>

                  <v-row justify="center">
                    <v-spacer> </v-spacer>

                    <v-btn
                      v-if="product_option != 'rule'"
                      class="mr-3"
                      @click="addDialog = true"
                    >
                      <div class="text-dark">Create New</div>
                    </v-btn>

                    <v-btn
                      v-if="product_option == 'rule'"
                      class="text-dark"
                      @click="ruleDialog = true"
                    >
                      Add products by rule
                    </v-btn>

                    <v-dialog
                      v-model="existingdialog"
                      persistent
                      max-width="900px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-spacer> </v-spacer>
                        <v-btn
                          v-if="product_option != 'rule'"
                          class="text-dark"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Add Existing
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Add Existing Product</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-data-table
                                  v-model="selectedProducts"
                                  :headers="headers"
                                  :items="searchInProductsToAssign"
                                  show-select
                                  class="elevation-1"
                                >
                                  <template #top>
                                    <v-toolbar flat>
                                      <v-text-field
                                        v-model="nameSearch"
                                        label="Search by Name"
                                        class="mx-4"
                                        append-icon="mdi-magnify"
                                      ></v-text-field>

                                      <v-text-field
                                        v-model="partIdSearch"
                                        label="Search by Part #"
                                        class="mx-4"
                                        append-icon="mdi-magnify"
                                      ></v-text-field>

                                      <v-text-field
                                        v-model="idSearch"
                                        label="Search by Id"
                                        class="mx-4"
                                        append-icon="mdi-magnify"
                                      ></v-text-field>

                                      <v-spacer></v-spacer>
                                    </v-toolbar>
                                  </template>
                                </v-data-table>
                              </v-col>
                              <!-- <v-col class="d-flex" cols="12" sm="12">
                                <v-autocomplete
                                  v-model="nameproduct"
                                  :items="real_prods"
                                  color="white"
                                  hide-no-data
                                  hide-selected
                                  item-text="name"
                                  item-value="id"
                                  label="Product"
                                  placeholder="Add product by Name"
                                  prepend-icon="mdi-database-search"
                                  @change="nameproductSelected(nameproduct)"
                                ></v-autocomplete>
                              </v-col>
                              <p>or</p>
                              <v-col class="d-flex" cols="12" sm="12">
                                <v-autocomplete
                                  v-model="pidproduct"
                                  :items="real_prods"
                                  color="white"
                                  hide-no-data
                                  hide-selected
                                  item-text="part_id"
                                  item-value="id"
                                  label="Product"
                                  placeholder="Add product by Part Id"
                                  prepend-icon="mdi-database-search"
                                  @change="pidproductSelected(pidproduct)"
                                >
                                  <template slot="item" slot-scope="{ item }">
                                    <p>{{ item.name }} ({{ item.part_id }})</p>
                                  </template>
                                </v-autocomplete>
                              </v-col>

                              <p>or</p>
                              <v-col class="d-flex" cols="12" sm="12">
                                <v-autocomplete
                                  v-model="idproduct"
                                  :items="real_prods"
                                  color="white"
                                  hide-no-data
                                  hide-selected
                                  item-text="id"
                                  item-value="id"
                                  label="Product"
                                  placeholder="Add product by ID"
                                  prepend-icon="mdi-database-search"
                                  @change="idproductSelected(idproduct)"
                                >
                                  <template slot="item" slot-scope="{ item }">
                                    <p>{{ item.name }} ({{ item.id }})</p>
                                  </template>
                                </v-autocomplete>
                              </v-col> -->
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="existingdialog = false"
                            >Close</v-btn
                          >
                          <v-btn color="blue darken-1" text @click="addExisting"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-toolbar>

                <v-tabs horizontal>
                  <v-tab>
                    <v-icon left>mdi-file</v-icon>
                    Cards
                  </v-tab>
                  <v-tab>
                    <v-icon left>mdi-file</v-icon>
                    List
                  </v-tab>

                  <v-tab-item>
                    <v-row class="mt-4">
                      <div
                        v-for="(item, index) in assortmentproducts"
                        :key="index"
                        class="col-sm-12  col-lg-4  "
                      >
                        <v-hover v-slot="{ hover }">
                          <v-card
                            class="mx-auto "
                            color="grey lighten-4"
                            max-width="600"
                            max-height="375"
                          >
                            <v-img
                              :src="
                                item.main_image != null
                                  ? item.main_image.url
                                  : '/media/product-placeholder.jpg'
                              "
                              :aspect-ratio="4 / 3"
                            >
                              <v-expand-transition>
                                <div
                                  v-if="hover"
                                  class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal  white--text"
                                  style="height: 100%;"
                                >
                                  <div class="container">
                                    <p>{{ item.name }}</p>
                                    <p
                                      v-if="item.short_description != null"
                                      style="font-size:12pt;"
                                      v-html="item.short_description"
                                    ></p>
                                  </div>
                                </div>
                              </v-expand-transition>
                            </v-img>
                            <v-card-text
                              class="pt-6"
                              style="position: relative;"
                            >
                              <v-btn
                                absolute
                                color="primary"
                                class="white--text"
                                fab
                                right
                                top
                                @click="
                                  $router.push('/pim/product-detail/' + item.id)
                                "
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                              <div class="font-weight-light grey--text  mb-2">
                                Part #: {{ item.part_id }}
                              </div>
                              <h3
                                class="display-1 font-weight-light orange--text mb-2 limit"
                              >
                                {{ item.name }}
                              </h3>
                              <v-card-actions>
                                <v-btn
                                  v-if="product_option != 'rule'"
                                  small
                                  @click="removeAccess(item.id)"
                                >
                                  Remove
                                </v-btn>
                              </v-card-actions>
                              <div class="font-weight-light title mb-2"></div>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </div>
                    </v-row>
                    <div class="row">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="productsCount"
                        :per-page="limit"
                        style="margin: 30px auto;"
                      ></b-pagination>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <v-data-table
                      striped
                      hover
                      :items="listassortmentproducts"
                      :headers="fields"
                    >
                      <template #[`item.index`]="{ index }">
                        {{ index + 1 }}
                      </template>
                      <template #[`item.name`]="{ item }">
                        {{ item.name }}
                      </template>
                      <template #[`item.actions`]="{ item }">
                        <v-btn
                          class="ma-2 mr-3"
                          tile
                          outlined
                          color="success"
                          @click="
                            $router.push('/pim/product-detail/' + item.id)
                          "
                        >
                          Edit
                        </v-btn>
                        <v-btn
                          v-if="product_option != 'rule'"
                          class="ma-2"
                          tile
                          outlined
                          color="success"
                          @click="removeAccess(item.id)"
                        >
                          Remove
                        </v-btn>
                      </template>
                    </v-data-table>

                    <!-- <div class="row">
                      <v-pagination
                        v-model="listcurrentPage"
                        :total-rows="totalCount"
                        :per-page="listlimit"
                        style="margin: 30px auto;"
                      ></v-pagination>
                    </div> -->
                  </v-tab-item>
                </v-tabs>
              </div>
            </b-form>

            <v-dialog v-model="addDialog" persistent max-width="600px">
              <v-form v-model="addDialogformvalid" @submit.prevent="addProduct">
                <v-card>
                  <v-card-title>
                    <span class="headline">Add New Product</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="addDialogform.name"
                            label="Product Name"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="addDialogform.partid"
                            label="Part #"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-checkbox
                            v-model="addDialogform.featured"
                            label="Featured in the profile"
                          ></v-checkbox>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-combobox
                            v-model="addchips"
                            :items="tagNames"
                            chips
                            clearable
                            :search-input.sync="addchipsearch"
                            label="Enter Tags followed by Enter"
                            multiple
                            persistent-hint
                            solo
                            @change="addchipsearch = ''"
                          >
                            <template
                              #selection="{
                                attrs,
                                item,
                                select,
                                selected,
                              }"
                            >
                              <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                close
                                @click="select"
                                @click:close="removeAddchip(item)"
                              >
                                <strong>{{ item }}</strong
                                >&nbsp;
                              </v-chip>
                            </template>
                          </v-combobox>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-card-actions>
                            <v-btn text>Main Image</v-btn>

                            <b-form-file
                              v-model="product_main_image"
                              :state="Boolean(product_main_image)"
                              placeholder="Upload Image"
                              drop-placeholder="Drop file here..."
                              accept="image/*"
                            ></b-form-file>

                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="addDialogClose">
                      Close
                    </v-btn>
                    <v-btn
                      type="submit"
                      :disabled="!addDialogformvalid"
                      color="blue darken-1"
                      text
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>

            <v-dialog v-model="ruleDialog" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Rules to Auto Assign Products</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <h4
                        v-if="ruleError"
                        style="color: red; margin: 0 auto;"
                        class="my-4"
                      >
                        You must have at least one tag!
                      </h4>
                      <v-col class="d-flex" cols="12" sm="12">
                        <v-combobox
                          v-model="rulechips"
                          :items="tagNames"
                          chips
                          clearable
                          :search-input.sync="chipsearch"
                          label="Enter Tags followed by Enter key. (TAGS are a OR Logic)"
                          multiple
                          hide-selected
                          solo
                          hint="Tags are great for using rules to sort or add"
                          @change="rulesearch = ''"
                        >
                          <template
                            #selection="{
                                  attrs,
                                  item,
                                  select,
                                  selected,
                                }"
                          >
                            <v-chip
                              v-bind="attrs"
                              :input-value="selected"
                              close
                              @click="select"
                              @click:close="removeRulechip(item)"
                            >
                              <strong>{{ item }}</strong
                              >&nbsp;
                            </v-chip>
                          </template>
                          <template #no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  No results matching "<strong>{{
                                    search
                                  }}</strong
                                  >". Press <kbd>enter</kbd> to create a new one
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                        <!-- <v-autocomplete
                          v-model="rulechips"
                          :items="tagNames"
                          chips
                          clearable
                          :search-input.sync="rulesearch"
                          label="Enter Tags followed by Enter key. (TAGS are a OR Logic)"
                          multiple
                          @change="rulesearch = ''"
                        >
                          <template
                            #selection="{ attrs, item, select, selected }"
                          >
                            <v-chip
                              v-bind="attrs"
                              :input-value="selected"
                              close
                              @click="select"
                              @click:close="removeRulechip(item)"
                            >
                              <strong>{{ item }}</strong
                              >&nbsp;
                            </v-chip>
                          </template>
                        </v-autocomplete> -->
                      </v-col>
                      <p>AND</p>
                      <v-col class="d-flex" cols="12" sm="12">
                        <b-form-select
                          v-model="rulestatus"
                          :options="logic"
                        ></b-form-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-overlay :opacity="0.46" :value="isLoading">
                    <v-progress-circular
                      indeterminate
                      size="36"
                    ></v-progress-circular>
                  </v-overlay>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="
                      ruleDialog = false
                      ruleError = false
                    "
                    >Close</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="addruleProducts"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'

import { validationMixin } from 'vuelidate'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import moment from 'moment'
import AssortmentProducts from '@/graphql/queries/AssortmentProducts.gql'
import GetListAssortmentProducts from '@/graphql/queries/GetListAssortmentProducts.gql'
import AssortmentAddProduct from '@/graphql/mutations/AssortmentAddProduct.gql'
import AssortmentRemoveProduct from '@/graphql/mutations/AssortmentRemoveProduct.gql'
import GetCompanyTags from '@/graphql/queries/GetCompanyTags.gql'
import UpdateFullAssortment from '@/graphql/mutations/UpdateFullAssortment.gql'
import UpdateAssortmentProducts from '@/graphql/mutations/UpdateAssortmentProducts.gql'
import CreateFullProduct from '@/graphql/mutations/CreateFullProduct.gql'
import UploadFile from '@/graphql/mutations/UploadFile.gql'
import UploadMainImage from '@/graphql/mutations/UploadMainImage.gql'
import GetProducts from '@/graphql/queries/GetProducts.gql'
import GetCategories from '@/graphql/queries/GetCategories.gql'
import GetAssortmentProductsCount from '@/graphql/queries/GetAssortmentProductsCount.gql'

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    },
  },
  mixins: [validationMixin],
  data: () => ({
    ruleError: false,
    chipsearch: '',
    addchipsearch: '',
    rulesearch: '',
    real_prods: [],
    nameexistingproducts: [],
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match",
    },
    product_main_image: null,
    addDialog: false,
    addDialogformvalid: false,
    addDialogform: {
      name: '',
      partid: '',
      featured: false,
    },
    addchips: [],
    isLoading: false,
    product_option: '',
    ruleDialog: false,
    listlimit: 10,
    listcurrentPage: 1,
    limit: 12,
    currentPage: 1,
    totalCount: 0,
    name: '',
    created_at: '',
    status: 'new',
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Part #', value: 'part_id' },
      { text: 'Global ID', value: 'id' },
    ],
    assortment_options: [
      { value: 'new', text: 'New' },
      { value: 'draft', text: 'Draft' },
      { value: 'awaiting', text: 'Awaiting Approval' },
      { value: 'active', text: 'Active ' },
      { value: 'disco', text: 'Discontinued' },
      { value: 'eol', text: 'End of Life' },
    ],
    description: '',
    short_description: '',
    chips: [],
    rulechips: [],
    rulestatus: '',
    tagHeader: [{ header: 'Select an option or create one' }],
    main_image_id: '',
    main_image_url: '',
    main_image: null,
    main_image_name: '',
    editor: ClassicEditor,
    editorConfig: {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          '|',
          'undo',
          'redo',
        ],
      },
    },
    baseurl: process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337',
    logic: [
      {
        value: '',
        text: 'All',
      },
      {
        value: 'new',
        text: 'New',
      },
      {
        value: 'active',
        text: 'Active',
      },
      {
        value: 'pending',
        text: 'Pending',
      },
      {
        value: 'discontinued',
        text: 'Discontinued',
      },
    ],
    isUpdating: false,
    existingdialog: false,
    dialog2: false,
    search: '',
    items: [],
    products: [],
    product: '',
    nameSearch: '',
    idSearch: '',
    partIdSearch: '',
    idproduct: '',
    idsearch: '',
    pidproduct: '',
    pidsearch: '',
    nameproduct: '',
    selectedProducts: [],
    namesearch: '',
    existingProductId: '',
    fields: [
      {
        value: 'index',
        text: '#',
      },
      {
        value: 'name',
        text: 'Product Name',
      },
      {
        value: 'actions',
        text: 'Actions',
      },
    ],
  }),
  computed: {
    tagNames: function() {
      const allTags = this.tags ? this.tags.map((t) => t.name) : []
      return [].concat(this.tagHeader, allTags)
    },
    productsToAssign() {
      if (this.listassortmentproducts && this.products) {
        const productsIdsinAssortment = this.listassortmentproducts.map(
          (product) => {
            return product.id
          }
        )
        const toDelete = new Set(productsIdsinAssortment)
        const newArray = this.products.filter((obj) => !toDelete.has(obj.id))
        return newArray
      } else {
        return []
      }
    },
    searchInProductsToAssign() {
      if (this.productsToAssign.length > 0) {
        const filterByword = this.productsToAssign.filter((p) => {
          const filter = this.nameSearch.toUpperCase()
          const filterByPartId = this.partIdSearch.toUpperCase()
          const filterById = this.idSearch
          const hasIdMatch = String(p.id).includes(filterById)
          const hasPartIdMatch = p.part_id
            .toUpperCase()
            .includes(filterByPartId)
          const hasNameMatch = p.name.toUpperCase().includes(filter)

          return hasNameMatch && hasPartIdMatch && hasIdMatch
        })
        return filterByword
      } else {
        return []
      }
    },
    channels() {
      if (this.categories) {
        if (this.categories.length > 0) {
          const channels = this.categories.map((category) => {
            return category.channel.name
          })
          return channels
        } else {
          return []
        }
      } else {
        return []
      }
    },
  },
  apollo: {
    tags: {
      query: GetCompanyTags,
      variables() {
        return {
          companyId: this.$store.state.auth.user.company_relation,
        }
      },
    },
    assortmentproducts: {
      query: AssortmentProducts,
      fetchPolicy: 'network-only',
      variables() {
        return {
          assortment: parseInt(this.$route.params.id),
          limit: this.limit,
          start: (this.currentPage - 1) * this.limit,
        }
      },
    },
    listassortmentproducts: {
      query: GetListAssortmentProducts,
      variables() {
        return {
          assortment: parseInt(this.$route.params.id),
        }
      },
    },
    products: {
      query: GetProducts,
      variables() {
        return {
          supplierId: this.$store.state.auth.user.company_relation,
          name_search: '',
          part_id_search: '',
          id_search: '',
        }
      },
    },
    categories: {
      query: GetCategories,
      variables() {
        return {
          assortmentId: this.$route.params.id,
        }
      },
    },
    productsCount: {
      query: GetAssortmentProductsCount,
      variables() {
        return {
          assortmentId: this.$route.params.id,
          name_search: '',
        }
      },
    },
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000)
      }
    },
  },
  created() {
    if (this.$route.params.id != '' && this.$route.params.id != undefined) {
      this.getAssortmentById({
        id: this.$route.params.id,
      }).then((res) => {
        if (res.data != false) {
          this.name = res.data.Name
          this.created_at = res.data.created_at
          this.status = res.data.status
          this.description = res.data.description
          this.product_option = res.data.product_option
          if (res.data.rule_tags != null) {
            this.rulechips = res.data.rule_tags.map((t) => t.name)
          }
          if (res.data.tags !== null) {
            this.chips = res.data.tags.map((t) => t.name)
          }
          this.rulestatus = res.data.rule_product_status
          if (res.data.main_image != null) {
            this.main_image_id = res.data.main_image.id
            this.main_image_url = res.data.main_image.url
            this.main_image_name =
              res.data.main_image.name + res.data.main_image.ext
          }

          this.$apollo.queries.assortmentproducts.refetch()
          this.$apollo.queries.listassortmentproducts.refetch()
        } else {
          this.$router.push('/pim/assortments')
          return
        }
      })

      // this.getCategoriesWhere({
      //   where: '?_where[0][assortment]=' + this.$route.params.id,
      // }).then((res) => {
      //   for (let i in res.data) {
      //     if (
      //       res.data[i].channel.name != undefined &&
      //       res.data[i].channel.name != null
      //     ) {
      //       if (!this.channels.includes(res.data[i].channel.name)) {
      //         this.channels.push(res.data[i].channel.name)
      //       }
      //     }
      //   }
      // })

      // this.getAssortmentProductTotalCount({
      //   params: 'assortments=' + this.$route.params.id,
      // }).then((res) => {
      //   this.totalCount = res.data
      // })

      // setTimeout(() => {
      //   // existing product dialog list
      //   this.getProductWhere({
      //     where:
      //       '?_where[0][supplier]=' +
      //       this.$store.state.auth.user.company_relation,
      //   }).then((res) => {
      //     let prods = []
      //     let ids = []
      //     this.real_prods = []
      //     for (let i in res.data) {
      //       if (res.data[i] != undefined) {
      //         ids.push(res.data[i].id)
      //         prods[res.data[i].id] = []
      //         prods[res.data[i].id]['name'] = res.data[i].name
      //         prods[res.data[i].id]['part_id'] = res.data[i].part_id
      //       }
      //     }
      //     this.getProductByAssortment({
      //       assortment: this.$route.params.id,
      //     }).then((resp) => {
      //       for (let i in resp.data) {
      //         let index = ids.indexOf(resp.data[i].id)
      //         if (index > -1) {
      //           ids.splice(index, 1)
      //         }
      //       }

      //       for (let i in ids) {
      //         this.real_prods.push({
      //           name: prods[ids[i]]['name'],
      //           part_id: prods[ids[i]]['part_id'],
      //           id: ids[i],
      //         })
      //       }
      //     })
      //   })
      // }, 1000)
    }
  },

  methods: {
    ...mapActions([
      'getAssortmentById',
      'deleteFile',
      'removeAssortment',
      'getAssortmentProductTotalCount',
      'getCategoriesWhere',
      'getProductByAssortment',
      'getProductWhere',
      'getAssortmentTotalCount',
      'submitItem',
      'removeProduct',
    ]),
    deleteAssortment() {
      if (this.$route.params.id != '' && this.$route.params.id != undefined) {
        this.$bvModal
          .msgBoxConfirm('Are you sure?', {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.removeAssortment({
                id: this.$route.params.id,
              })
                .then(() => {
                  Swal.fire({
                    title: '',
                    text: 'The assortment has been removed.',
                    icon: 'success',
                    confirmButtonClass: 'btn btn-secondary',
                  })

                  this.$router.push('/pim/assortments')
                })
                .catch(() => {
                  this.$bvToast.toast('An Error Occured!', {
                    title: 'Error',
                    variant: 'danger',
                    toaster: 'b-toaster-bottom-right',
                    solid: true,
                    appendToast: true,
                  })
                })
            }
          })
      }
    },
    async removeAssortmentproduct(id) {
      await this.$apollo.mutate({
        mutation: AssortmentRemoveProduct,
        variables: { assortmentId: this.$route.params.id, productId: id },
      })
      this.refreshAssortmentProduct()
    },
    removeAccess(id) {
      if (id != '' && id != undefined) {
        this.$bvModal
          .msgBoxConfirm('Are you sure?', {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.removeAssortmentproduct(id)
                .then(() => {
                  Swal.fire({
                    title: '',
                    text: 'The assortment has been successfully updated.',
                    icon: 'success',
                    confirmButtonClass: 'btn btn-secondary',
                  })
                })
                .catch(() => {
                  this.$bvToast.toast('An Error Occured!', {
                    title: 'Error',
                    variant: 'danger',
                    toaster: 'b-toaster-bottom-right',
                    solid: true,
                    appendToast: true,
                  })
                })
            }
          })
      }
    },
    async addruleProducts() {
      if (this.rulechips.length > 0) {
        this.ruleError = false
        this.isLoading = true

        const assormentUpdate = {
          rule_product_status: this.rulestatus,
        }

        await this.$apollo.mutate({
          mutation: UpdateFullAssortment,
          variables: {
            assormentUpdate,
            id: this.$route.params.id,
            searchRuleTags: this.rulechips,
          },
        })

        this.isLoading = false

        this.ruleDialog = false

        Swal.fire({
          title: '',
          text: 'The assortment has been successfully updated!',
          icon: 'success',
          confirmButtonClass: 'btn btn-secondary',
        })
        // refreshes tags incase a new one was created
        this.$apollo.queries.tags.refetch()

        // refresh product list
        this.refreshAssortmentProduct()
      } else {
        this.ruleError = true
      }
    },
    refreshAssortmentProduct() {
      // this.getAssortmentProductTotalCount({
      //   params: 'assortments=' + this.$route.params.id,
      // }).then((res) => {
      //   this.totalCount = res.data
      // })

      this.$apollo.queries.assortmentproducts.refetch()
      this.$apollo.queries.listassortmentproducts.refetch()
      this.$apollo.queries.products.refetch()
      this.$apollo.queries.productsCount.refetch()

      // existing product dialog list
      // setTimeout(() => {
      //   this.getProductWhere({
      //     where:
      //       '?_where[0][supplier]=' +
      //       this.$store.state.auth.user.company_relation,
      //   }).then((res) => {
      //     let prods = []
      //     let ids = []
      //     this.real_prods = []
      //     for (let i in res.data) {
      //       if (res.data[i] != undefined) {
      //         ids.push(res.data[i].id)
      //         prods[res.data[i].id] = []
      //         prods[res.data[i].id]['name'] = res.data[i].name
      //         prods[res.data[i].id]['part_id'] = res.data[i].part_id
      //       }
      //     }
      //     this.getProductByAssortment({
      //       assortment: this.$route.params.id,
      //     }).then((resp) => {
      //       for (let i in resp.data) {
      //         let index = ids.indexOf(resp.data[i].id)
      //         if (index > -1) {
      //           ids.splice(index, 1)
      //         }
      //       }

      //       for (let i in ids) {
      //         this.real_prods.push({
      //           name: prods[ids[i]]['name'],
      //           part_id: prods[ids[i]]['part_id'],
      //           id: ids[i],
      //         })
      //       }
      //     })
      //   })
      // }, 1000)
    },
    async submitAssortmentproduct(assortmentId, productId) {
      await this.$apollo.mutate({
        mutation: AssortmentAddProduct,
        variables: { assortmentId: assortmentId, productId },
      })
    },
    async addExisting() {
      if (this.listassortmentproducts.length > 0) {
        const productsIds = this.listassortmentproducts.map((product) => {
          return product.id
        })
        const selectedProductsId = this.selectedProducts.map((product) => {
          return product.id
        })
        const addProductsIds = selectedProductsId.concat(productsIds)
        await this.$apollo.mutate({
          mutation: UpdateAssortmentProducts,
          variables: {
            id: this.$route.params.id,
            productIds: addProductsIds,
          },
        })
      } else {
        const selectedProductsId = this.selectedProducts.map((product) => {
          return product.id
        })
        await this.$apollo.mutate({
          mutation: UpdateAssortmentProducts,
          variables: {
            id: this.$route.params.id,
            productIds: selectedProductsId,
          },
        })
      }
      this.existingdialog = false
      this.nameSearch = ''
      this.partIdSearch = ''
      this.idSearch = ''
      this.selectedProducts = []
      // refresh product list
      this.refreshAssortmentProduct()
      Swal.fire({
        title: '',
        text: 'The assortment has been successfully updated!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      })
    },
    nameproductSelected(id) {
      this.existingProductId = id
      this.pidproduct = ''
      this.idproduct = ''
    },
    idproductSelected(id) {
      this.existingProductId = id
      this.pidproduct = ''
      this.nameproduct = ''
    },
    pidproductSelected(id) {
      this.existingProductId = id
      this.idproduct = ''
      this.nameproduct = ''
    },
    removeRulechip(item) {
      let index = this.rulechips.indexOf(item)
      if (index > -1) {
        this.rulechips.splice(index, 1)
      }
    },
    removeChip(item) {
      let index = this.chips.indexOf(item)
      if (index > -1) {
        this.chips.splice(index, 1)
      }
    },
    imageDelete() {
      this.main_image = ''
      this.main_image_name = ''
      this.main_image_url = ''
    },
    imageChange(e) {
      let target = e.target || e.srcElement
      let files = target.files
      let fr = new FileReader()
      fr.onload = function() {
        document.getElementById('image_cover').src = fr.result
      }
      fr.readAsDataURL(files[0])
    },
    async submit(e) {
      e.preventDefault()
      //guard
      if (this.$route.params.id === '' || this.$route.params.id === undefined) {
        return
      }
      const assormentUpdate = {
        Name: this.name,
        description: this.description,
        status: this.status,
      }
      const hasImageToUpload = this.main_image instanceof File
      const isDeletingImage = this.main_image === ''
      const hasDeletableImage =
        this.main_image_id !== undefined && this.main_image_id !== ''

      try {
        if ((hasImageToUpload || isDeletingImage) && hasDeletableImage) {
          await this.deleteFile({
            id: this.main_image_id,
          })
        }

        if (hasImageToUpload) {
          await this.$apollo.mutate({
            mutation: UploadFile,
            variables: {
              contentType: 'assortment',
              contentId: this.$route.params.id,
              field: 'main_image',
              file: this.main_image,
            },
          })
        }
        await this.$apollo.mutate({
          mutation: UpdateFullAssortment,
          variables: {
            assormentUpdate,
            id: this.$route.params.id,
            searchTags: this.chips,
          },
        })
        Swal.fire({
          title: '',
          text: 'The assortment has been successfully updated!',
          icon: 'success',
          confirmButtonClass: 'btn btn-secondary',
        })
      } catch (error) {
        this.$bvToast.toast('An Error Occured!', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true,
        })
      }
    },
    removeAddchip(item) {
      let index = this.addchips.indexOf(item)
      if (index > -1) {
        this.addchips.splice(index, 1)
      }
    },
    addDialogClose() {
      this.addDialogform.name = ''
      this.addDialogform.partid = ''
      this.addchips = []
      this.addDialog = false
    },
    async addProduct() {
      var newProduct = ''
      if (this.product_main_image) {
        const productImage = await this.$apollo.mutate({
          mutation: UploadMainImage,
          variables: {
            file: this.product_main_image,
          },
        })
        newProduct = {
          name: this.addDialogform.name,
          part_id: this.addDialogform.partid,
          featured: this.addDialogform.featured,
          supplier: this.$store.state.auth.user.company_relation,
          main_image: productImage.data.upload.id,
          assortments: [this.$route.params.id],
        }
      } else {
        newProduct = {
          name: this.addDialogform.name,
          part_id: this.addDialogform.partid,
          featured: this.addDialogform.featured,
          supplier: this.$store.state.auth.user.company_relation,
          assortments: [this.$route.params.id],
        }
      }

      const res = await this.$apollo.mutate({
        mutation: CreateFullProduct,
        variables: {
          newProduct,
          searchTags: this.addchips,
        },
      })
      // creates a default item for a product
      await this.submitItem({
        Name: 'default',
        sku_id: this.part_id,
        product: res.data.createFullProduct.product.id,
      })

      // refreshes tags incase a new one was created
      this.$apollo.queries.tags.refetch()
      // add product to assortment
      this.refreshAssortmentProduct()
      this.addDialogform.name = ''
      this.addDialogform.partid = ''
      this.addchips = []
      this.addDialog = false

      Swal.fire({
        title: '',
        text: 'The product has been successfully created!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      })
    },
  },
}
</script>

<style>
.limit {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
</style>
